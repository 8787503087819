import Vue from 'vue'
import CustomTable from '../components/table/CustomTable.vue'
import DatePickerModel from '../components/common/DatePickerModel.vue'
import SelectWarehouseModel from '../components/common/SelectWarehouseModel.vue'
import InputQrScanModel from '../components/common/InputQrScanModel.vue'
import SelectEmployeeModel from '../components/common/SelectEmployeeModel.vue'
import SelectCustomerByWarehouse from '../components/common/SelectCustomerByWarehouse.vue'
import SelectMultiple from '../components/common/SelectMultiple.vue'
import InputNumber from '../components/common/InputNumber.vue'
import Otp from '../components/common/Otp.vue'

Vue.component(DatePickerModel.name, DatePickerModel)
Vue.component(CustomTable.name, CustomTable)
Vue.component(SelectWarehouseModel.name, SelectWarehouseModel)
Vue.component(InputQrScanModel.name, InputQrScanModel)
Vue.component(SelectEmployeeModel.name, SelectEmployeeModel)
Vue.component(SelectCustomerByWarehouse.name, SelectCustomerByWarehouse)
Vue.component(SelectMultiple.name, SelectMultiple)
Vue.component(InputNumber.name, InputNumber)
Vue.component(Otp.name, Otp)
